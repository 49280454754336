<template>
  <div>
    <div class="w-3/5">
      <h1
        class="text-xl font-medium font-headers mb-3 font-bold text-grey-dark-2"
      >
        <span class="text-grey-medium-2 font-normal">
          Companies / {{ currentCompany.name }} /
        </span>
        Admin Details
      </h1>
    </div>
    <div class="bg-white px-2 pt-3 pb-5 shadow flex-grow-0 rounded">
      <div class="flex justify-between items-start">
        <h1 class="font-headers font-bold text-grey-light text-mdl">
          Company Admin Details
        </h1>
        <Button
          text="Save "
          type="primary"
          size="medium"
          :disabled="disableSave || showErr"
          @click.native="update"
          data-testid="save-button"
        />
      </div>
      <div
        class="px-2 flex flex-row w-full justify-between max-w-3/5 mt-2 mb-3"
      >
        <div class="w-1/2 pr-2">
          <InputField
            placeholder="First name"
            label="First name"
            type="text"
            :defaultValue="firstName"
            :onChange="(val) => setValue('firstName', val)"
            data-testid="first-name-input"
          />
        </div>
        <div class="w-1/2">
          <InputField
            placeholder="Last name"
            label="Last name"
            type="text"
            :defaultValue="lastName"
            :onChange="(val) => setValue('lastName', val)"
            data-testid="last-name-input"
          />
        </div>
      </div>
      <InputField
        placeholder="Email"
        label="Email"
        type="text"
        :disabled="true"
        :defaultValue="email"
        :onChange="(val) => setValue('email', val)"
        class="mb-2 px-2 max-w-3/5"
        data-testid="email-field"
      />
      <!-- <div class="flex flex-col mt-2">
        <p class="text-grey-dark-1 text-md mb-1 font-semibold">
          Remove Admin Rights
        </p>
        <div class="ml-2">
          <p class="text-grey-light text-sm-2">
            If you remove this user as admin, they will no longer be able to
            manage this company. Other roles of the user will not be affected.
            If the user doesn't have other roles, their account will be
            deactivated.
          </p>
          <div class="flex flex-row mt-1.5">
            <div class="pt-0.5">
              <Checkbox
                class="cursor-pointer"
                :active="permisions.permisionCheck"
                @click.native="check('permisionCheck')"
                data-testid="permission-checkbox"
              />
            </div>
            <p
              class="text-mdh pl-1 cursor-pointer text-grey-dark-2"
              @click="check('permisionCheck')"
            >
              I understand that I am removing admin permission for this person.
            </p>
          </div>
          <div class="flex flex-row mt-1.5">
            <div class="pt-0.5">
              <Checkbox
                class="cursor-pointer"
                :active="permisions.roleCheck"
                @click.native="check('roleCheck')"
                data-testid="role-checkbox"
              />
            </div>
            <p
              class="text-mdh pl-1 cursor-pointer text-grey-dark-2"
              @click="check('roleCheck')"
            >
              I understand that if this user has no other roles, their account
              will be deactivated.
            </p>
          </div>
          <p v-if="showErr" class="text-red text-mdh">
            Both options should be checked in order to remove admin rights.
          </p>
        </div>
      </div> -->
    </div>
    <div class="mt-2 bg-white px-2 pt-3 pb-5 shadow flex-grow-0 rounded">
      <h1 class="font-headers font-bold text-grey-light text-mdl">
        Remove Admin Rights
      </h1>
      <div class="px-2 mt-1.5 flex justify-between items-start">
        <p class="text-mdh text-grey-dark-1 max-w-3/5">
          If you remove this user as admin, they will no longer be able to
          manage this company. Other roles of the user will not be affected. If
          the user doesn’t hve other roles, their account will be deactivated.
        </p>
        <button class="text-red text-md font-semibold" @click="remove">
          Remove Admin Rights
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "EditAdmin",
  data: () => ({
    permisions: {
      permisionCheck: false,
      roleCheck: false,
    },
  }),
  computed: {
    ...mapState({
      selectedUser: (state) => state.companies.selectedUser,
      currentCompany: (state) => state.companies.currentCompany,
    }),
    firstName: {
      get() {
        return this.selectedUser.firstName;
      },
      set(value) {
        this.selectedUser.firstName = value;
      },
    },
    lastName: {
      get() {
        return this.selectedUser.lastName;
      },
      set(value) {
        this.selectedUser.lastName = value;
      },
    },
    email() {
      return this.selectedUser.email;
    },
    id() {
      return this.selectedUser.id;
    },
    disableSave() {
      return !this.firstName || !this.lastName || !this.email;
    },
    showErr() {
      if (!this.permisions.roleCheck && !this.permisions.permisionCheck) {
        return;
      }
      if (this.permisions.roleCheck && this.permisions.permisionCheck) {
        return;
      }
      return true;
    },
  },
  methods: {
    ...mapActions({
      editCompanyAdmin: "companies/editCompanyAdmin",
      openModal: "modals/openModal",
    }),
    setValue(field, val) {
      this[field] = val;
    },
    check(permision) {
      this.permisions[permision] = !this.permisions[permision];
    },

    async update() {
      const payload = {
        id: this.id,
        firstName: this.firstName,
        lastName: this.lastName,
		email: this.email,
        removeAdminRights:
          this.permisions.permisionCheck && this.permisions.roleCheck,
      };
      await this.editCompanyAdmin({
        id: this.currentCompany.id,
        payload,
      });
      this.$router.push(`/company/${this.currentCompany.id}/overview`);
    },
    remove() {
      this.openModal({ modal: "removeAdminRightsModal" });
    },
  },
};
</script>
